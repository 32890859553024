<template>
    <div class="transaction">
        <div style="position: relative">
            <van-search :placeholder="$t('搜索')" readonly @click="showSearch">
            </van-search>
        </div>
        <van-list
                v-model="loading"
                :finished="finished"
                immediate-check
                :finished-text="$t('没有更多了')"
                :loading-text="$t('加载中')"
                @load="doSearch"
        >
            <table class="transaction-table" cellpadding="0" cellspacing="0">
                <tr class="transaction-table-head">
                    <th>{{ $t("交易日期") }}</th>
                    <th>{{ $t("HS编码") }}</th>
                    <th>{{ $t("客户") }}</th>
                    <th>{{ $t("供应商") }}</th>
                    <th>{{ $t("产品描述") }}</th>
                    <th>{{ $t("数量") }}</th>
                    <th>{{ $t("单价") }}</th>
                    <th>{{ $t("总金额") }}</th>
                    <th>{{ $t("交易方式") }}</th>
                    <th>{{ $t("付款方式") }}</th>
                </tr>

                <tr v-for="item in list" :key="item.id">
                    <td>{{ item.date }}</td>
                    <td>{{ item.hs_code }}</td>
                    <td class="table-td" @click="showPopupForSup(item)">
                        {{ item.cus_name }}
                    </td>
                    <td class="table-td" @click="showPopupForCus(item)">
                        {{ item.sup_name }}
                    </td>
                    <td class="table-td">{{ item.goods_desc }}</td>
                    <td>{{ item.quantity }}</td>
                    <td>{{ item.unit_price }}</td>
                    <td>{{ item.total_price }}</td>
                    <td>{{ item.pay_term }}</td>
                    <td>{{ item.trade_term }}</td>
                </tr>
            </table>
        </van-list>
        <van-popup
                v-model="supshow"
                position="bottom"
                custom-style="height: 30%;"
                bind:close="onClose"
        >
            <van-cell-group>
                <van-cell
                        :title="$t('立即询盘')"
                        @click="showInquiry"
                        center
                />
                <van-cell
                        :title="$t('查看供应商')"
                        @click="showSupplier"
                        center
                />
            </van-cell-group>
        </van-popup>
        <van-popup
                v-model="cusshow"
                position="bottom"
                custom-style="height: 30%;"
                bind:close="onClose"
        >
            <van-cell-group>
                <van-cell
                        :title="$t('发出报价')"
                        @click="showPrice"
                        center
                />
                <van-cell
                        :title="$t('查看客户')"
                        @click="showCustomer"
                        center
                />
                <van-cell
                        :title="$t('评价客户')"
                        @click="evaluateCustomer"
                        center
                />
            </van-cell-group>
        </van-popup>
        <transaction-search
                ref="search"
                flag="transaction"
                @search="onSearch"
        ></transaction-search>
        <inquiry ref="inquiry" :item="item" @search="onSearch"></inquiry>
        <supcompany ref="supcompany" :item="item" @search="onSearch"></supcompany>
        <cuscompany ref="cuscompany" :item="item" @search="onSearch" ></cuscompany>
        <goodsprice ref="goodsprice" :item="item" @search="onSearch"></goodsprice>
    </div>
</template>

<script>
    import TransactionSearch from "../components/TransactionSearch.vue";
    import Inquiry from "../components/Inquiry.vue";
    import ShowSupplier from "../components/ShowSupplier";
    import ShowCustomer from "../components/ShowCustomer";
    import GoodsPrice from "../components/GoodsPrice";

    export default {
        name: "Supplier",
        data() {
            return {
                nowIdentity:1,//当前登录身份
                list: [],
                item: {}, // 选中供应商数据
                params: {
                    page: 1, // 页数
                    hs: "", // hs编码
                    pro: "", // 产品
                    cus: "", // 客户
                    sup: "", // 供应商
                    start_time: "", // 开始时间
                    end_time: "", //  结束时间
                    ori_port: "", // 原始港口
                    loa_port: "", // 途径港口
                    dis_port: "", // 目标港口
                    up_from: "", // 最低单价
                    up_to: "", // 最高单价
                    tp_from: "", // 最低总价
                    tp_to: "", // 最高总价
                },
                loading: false,
                finished: false,
                supshow: false,
                cusshow:false,
            };
        },
        components: {
            "transaction-search": TransactionSearch,
            "supcompany": ShowSupplier,
            "cuscompany": ShowCustomer,
            "goodsprice": GoodsPrice,
            inquiry: Inquiry,
        },
        mounted() {
            this.nowIdentity = this.$store.state.identity;
            this.params.end_time = this.curentTime();
            this.params.start_time = this.calStartTime();
        },
        beforeRouteLeave(to, from, next) {
            if (this.$refs.search.show) {
                this.$refs.search.toggleShow();
                next(false);
            } else if (this.$refs.inquiry.show) {
                this.$refs.inquiry.toggleShow();
                next(false);
            }else if (this.$refs.supcompany.show) {
                this.$refs.supcompany.toggleShow();
                next(false);
            } else if (this.$refs.cuscompany.show) {
                this.$refs.cuscompany.toggleShow();
                next(false);
            }else if (this.$refs.goodsprice.show) {
                this.$refs.goodsprice.toggleShow();
                next(false);
            }else {
                next();
            }
        },
        methods: {
            showPopupForCus(obj) {
                if(this.nowIdentity==1){
                    this.item = obj
                    this.supshow = true;
                }
            },
            showPopupForSup(obj){
                if(this.nowIdentity==2){
                    this.item = obj
                    this.cusshow = true;
                }
            },
            onClosePopup() {
                this.supshow = false;
                this.cusshow = false;
            },
            //显示询价页面
            showInquiry() {
                // this.$refs.inquiry.id = this.item.id;
                this.$refs.inquiry.goodsValue = this.item.goods?this.item.goods:this.params.pro;
                this.$refs.inquiry.supValue = this.item.sup_name;
                // 显示询单框
                this.supshow = false;
                this.$refs.inquiry.toggleShow();
            },
            //展示供应商企业信息
            showSupplier() {
                this.supshow = false;
                this.$refs.supcompany.sup = this.item.sup_name;
                this.$refs.supcompany.toggleShow();
            },
            //展示客户企业信息
            showCustomer(){
                this.cusshow = false;
                this.$refs.cuscompany.cus = this.item.cus_name;
                this.$refs.cuscompany.toggleShow();
            },
            //展示客户评价页面
            evaluateCustomer(){

            },
            //展示报价界面
            showPrice(){
                this.$refs.goodsprice.cus_ids = [];
                this.$refs.goodsprice.withCus = true;
                this.$refs.goodsprice.withGoods = true;
                this.$refs.goodsprice.cusValue = this.item.cus_name;
                this.$refs.goodsprice.goodsValue = this.item.goods?this.item.goods:this.params.pro;
                this.$refs.goodsprice.setCusId();

                this.cusshow = false;
                this.$refs.goodsprice.toggleShow();
            },
            showSearch() {
                // 显示搜索框
                this.$refs.search.toggleShow();
            },
            onSearch(val) {
                // 获取搜索
                val.page = 1;
                this.list = [];
                this.params = {...this.params, ...val};
                this.finished = false;
                this.loading = true;
                this.doSearch();
            },
            doSearch() {
                // 查询交易数据
                this.$http.post("/v1/doSearch", this.params).then((res) => {
                    if (res.code == 200) {
                        this.list = this.list.concat(res.data.list.data);
                        this.loading = false;
                        this.finished = res.data.list.data.length < res.data.list.per_page;
                        this.params.page += 1;
                    }
                });
            },
            curentTime() {
                var now = new Date();
                var year = now.getFullYear(); //年
                var month = now.getMonth() + 1; //月
                var day = now.getDate(); //日
                var clock = year + "-";
                var startMonth = month - 3;
                if (month < 10) clock += "0";
                clock += month + "-";
                if (day < 10) clock += "0";
                clock += day + " ";
                //默认起始时间
                if (startMonth < 10) startMonth = "0" + startMonth;
                this.params.start_time = year + "-" + startMonth + "-" + day;

                return clock;
            },
            calStartTime() {
                var now = new Date();
                var year = now.getFullYear(); //年
                var month = now.getMonth() + 1; //月
                var clock = year + "-";
                month = month - 3;
                if (month < 10) clock += "0";
                clock += month + "-";
                clock += "01";
                return clock;
            },
        },
    };
</script>

<style scoped>
    .transaction {
        color: #606266;
    }

    .transaction-table {
        display: block;
        max-width: 100vw;
        overflow: scroll;
    }

    .transaction-table-list {
        display: flex;
        border-bottom: 1px solid #efefef;
    }

    .transaction-table-list > div {
        text-align: center;
        padding: 12px 16px;
        flex-shrink: 0;
        /* width: 80px; */
    }

    .transaction-table td,
    .transaction-table th {
        white-space: nowrap;
        padding: 12px 16px;
        border-bottom: 1px solid #f5f5f5;
        text-align: left;
    }

    .transaction-table td {
    }

    .transaction-table tr:nth-child(2n + 3) {
        background: #f5f5f5;
    }

    .table-td {
        display: table-cell;
        width: 200px;
        white-space: pre-wrap !important;
    }
    .van-cell__title{
        text-align: center;
    }
</style>